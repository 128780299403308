import React from 'react'
import { useEffect } from 'react'
import '../../components/styles/global.scss'
import SegmentedControl from '../../components/common/SegmentedControl'
import Layout from '../../components/common/Layout'
import { useStaticQuery, graphql } from 'gatsby'

export default function ResourcesPublications() {

    const data = useStaticQuery(graphql`
          query Publications {
            allDataYaml(filter: {yamlId: {eq: "publications"}}) {
              nodes {
                publications {
                    category
                    title
                    author
                    journal
                    date
                    url
                }
              }
            }
          }
        `),
        publications = data.allDataYaml.nodes[0].publications,
        categories = [...new Set(publications.map(item => item.category))].sort()
    
    useEffect(() => {
        const   select = document.getElementById('results-select'),
                results = document.getElementById('results')

        select.addEventListener('change', (e)=>{
            const   filterWith = e.target.value,
                    filteredPublications = publications.filter(publication => publication.category === filterWith),
                    lastIndex = filteredPublications.length - 1
            
            filteredPublications.sort((a, b) => b.date-a.date)
            results.innerHTML = ''
            results.classList.add('resources-results')
            
            //console.log(filteredPublications)

            for(let i = 0, len = filteredPublications.length; i < len; i++){
                const   publication = filteredPublications[i],
                        link = document.createElement('a'),
                        triangle = document.createElement('span'),
                        attribution = document.createElement('p'),
                        isLast = i === lastIndex,
                        divider = document.createElement('span')

                link.target = '_blank'
                link.rel = 'noopener noreferrer'
                link.href = publication.url
                attribution.innerHTML = publication.author + ' <i>' + publication.journal + '</i>. ' +  publication.date + '.'
                triangle.classList.add('triangle')
                link.append(publication.title, triangle)
                results.append(link, attribution)
                divider.classList.add('divider')
                !isLast && results.appendChild(divider)
            }
        })
    }, [])

    return (
        <Layout active='publications'
        title='PharmaEssentia Medical Affairs | Publications'
        description='Scientific publications provided for educational purposes, reflecting PharmaEssentia’s dedication to ensuring healthcare professionals have access to the latest clinical data.'
        socialTitle='PharmaEssentia Medical Affairs | Publications'
        socialDescription='Scientific publications provided for educational purposes, reflecting PharmaEssentia’s dedication to ensuring healthcare professionals have access to the latest clinical data.'
        pathname='/resources/publications'
        >
            <section>
                <div className='inner'>
                    <h1>Resources</h1>
                    <SegmentedControl additionalClasses='space-between' buttons='resources' active='publications'/>
                    <h3>Publications</h3>
                    <p>PharmaEssentia Medical Affairs is dedicated to ensuring that healthcare professionals have immediate access to the latest scientific and clinical data. The scientific publications below are provided for educational purposes only. Some publications may discuss the use of an approved product for investigational use or an investigational agent for an indication that has not been approved by the US FDA.</p>
                    <h4 className='bold primary'>Browse our clinical trials that have been published in peer-reviewed journals:</h4>
                    <div className="select-wrapper percent-33">
                        <select id='results-select' defaultValue={'DEFAULT'}>
                            <option value='DEFAULT' disabled>Select a category</option>
                            {
                                categories.map((item, index)=>(
                                    <option value={item} key={index + 'option'}>{item}</option>
                                ))
                            }
                        </select>
                        <span className="triangle-wrapper">
                            <span className="triangle"></span>
                        </span>
                    </div>
                    <div id='results'>
                        
                    </div>
                    
                    
                </div>
            </section>
        </Layout>
    )
}